<template>
  <div>
    <notifications group="notification" position="bottom right" />

    <div class="row">
      <div class="col-12">
        <div
          v-if="costSheets.length > 0"
          class="card card-custom gutter-b card-stretch"
        >
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("MENU.ALL_COST_SHEETS") }}
                <span class="text-muted pt-2 font-size-sm d-block">{{
                  $t("COSTSHEET.LIST_OF_COSTSHEETS")
                }}</span>
              </h3>
            </div>
            <div class="card-toolbar">
              <!--begin::Dropdown-->
              <div class="mr-2">
                <button
                  @click.prevent="exportXLSX"
                  type="button"
                  :class="{
                    'spinner spinner-primary spinner-right': isExportInProgress,
                  }"
                  class="btn btn-light-primary font-weight-bolder"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="media/svg/icons/Files/Export.svg" />
                  </span>
                  Export to .xlsx
                </button>
              </div>
              <!--end::Dropdown-->

              <!--begin::Button-->
              <router-link
                to="/cost-sheets/new"
                class="btn btn-primary font-weight-bolder"
                ><span class="svg-icon svg-icon-md">
                  <inline-svg src="media/svg/icons/Shopping/Calculator.svg" />
                </span>
                {{ $t("COSTSHEET.NEW_COSTSHEET") }}</router-link
              >
              <!--end::Button-->
            </div>
          </div>
          <div class="card-body pt-10">
            <div v-if="isCostSheetsLoaded" class="row">
              <div class="col-md-12">
                <div class="table-responsive">
                  <table
                    class="table table-head-custom table-head-bg table-vertical-center mb-0"
                  >
                    <thead>
                      <tr class="bg-gray-100 text-left">
                        <th style="min-width: 250px" class="pl-7">
                          <span
                            @click="orderEntityByName"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': nameOrder.isActive }"
                            >
                              {{ $t("LIST.NAME") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': nameOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="nameOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 130px">
                          <span
                            @click="orderEntityByConstruction"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{
                                'text-primary': constructionOrder.isActive,
                              }"
                              >{{ $t("GENERAL.CONSTRUCTION_SITE") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': constructionOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="constructionOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 150px">
                          <span
                            @click="orderEntityByAmount"
                            class="cursor-pointer"
                          >
                            <span
                              class="pt-2"
                              :class="{ 'text-primary': amountOrder.isActive }"
                              >{{ $t("COSTSHEET.COST") }}</span
                            >
                            <span
                              class="svg-icon svg-icon-sm pl-2"
                              :class="{
                                'svg-icon-primary': amountOrder.isActive,
                              }"
                            >
                              <inline-svg
                                v-if="amountOrder.query === 'asc'"
                                src="media/svg/icons/Navigation/Down-2.svg"
                              />
                              <inline-svg
                                v-else
                                src="media/svg/icons/Navigation/Up-2.svg"
                              />
                            </span>
                          </span>
                        </th>
                        <th style="min-width: 150px">
                          {{ $t("COSTSHEET.BILLING_DATE") }}
                        </th>
                        <th style="min-width: 170px" class="pr-7 text-right">
                          {{ $t("MENU.ACTIONS") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(costSheet, key) in costSheets" :key="key">
                        <td
                          class="pl-0 py-4 pl-7"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <div class="d-flex align-items-center">
                            <div>
                              <router-link
                                :to="'/cost-sheets/edit/' + costSheet.id"
                                class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                >{{ costSheet.name }}</router-link
                              >
                              <span class="text-muted font-weight-bold d-block"
                                >{{ $t("COSTSHEET.COST_DESCRIPTION") }} :
                                {{ costSheet.note }}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <a
                            v-if="costSheet.construction"
                            href="#"
                            @click.prevent="
                              showConstruction(costSheet.construction.id)
                            "
                            class="font-weight-bolder text-primary cursor-pointer mb-0"
                            >{{ costSheet.construction.name }}</a
                          >
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span v-if="costSheet.amount">{{
                            costSheet.amount | currencyFormat
                          }}</span>
                        </td>
                        <td :class="{ 'border-top-0': key === 0 }">
                          <span>{{ costSheet.billingDate | formatDate }}</span>
                        </td>
                        <td
                          class="pr-7 text-right"
                          :class="{ 'border-top-0': key === 0 }"
                        >
                          <a
                            href="#"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                            @click.prevent="showCostSheet(costSheet.id)"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Visible.svg"
                                title="View"
                                aria-label="View"
                              />
                            </span>
                          </a>
                          <router-link
                            :to="'/cost-sheets/edit/' + costSheets.id"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon mr-2"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/Design/Edit.svg"
                                title="Edit"
                                aria-label="Edit"
                              />
                            </span>
                          </router-link>
                          <button
                            @click="removeCostSheet(costSheet.id)"
                            class="btn btn-sm btn-default btn-text-primary btn-hover-primary btn-icon"
                          >
                            <span class="svg-icon svg-icon-md">
                              <inline-svg
                                src="media/svg/icons/General/Trash.svg"
                              />
                            </span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else class="row">
              <div class="col-md-12 text-center">
                <b-spinner variant="primary" type="grow"></b-spinner>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="alert alert-primary py-4" role="alert">
          {{ $t("COSTSHEET.NO_COSTSHEET_IN_DATABASE") }}
          <router-link
            to="/cost-sheets/new"
            style="
              color: #ffffff;
              float: right;
              font-weight: 600;
              text-decoration: underline;
            "
            >{{ $t("COSTSHEET.ADD_COSTSHEET") }}</router-link
          >
        </div>
      </div>
    </div>

    <b-modal
      id="showConstructionModal"
      hide-header
      hide-footer
      size="md"
      ref="showConstructionModal"
    >
      <div v-if="isSelectedConstructionLoaded" class="text-left">
        <div v-if="selectedConstruction.name" class="mb-2">
          <strong>{{ $t("LIST.NAME") }}: </strong
          ><span>{{ selectedConstruction.name }}</span>
        </div>
        <div v-if="selectedConstruction.address" class="mb-2">
          <strong>{{ $t("GENERAL.ADDRESS") }}</strong
          ><span
            ><a
              :href="
                'https://www.google.com/maps/place/' +
                selectedConstruction.address
              "
              target="_blank"
              >{{ selectedConstruction.address }}</a
            ></span
          >
        </div>
        <div v-if="selectedConstruction.started" class="mb-2">
          <strong>{{ $t("GENERAL.START") }}: </strong
          ><span>{{ selectedConstruction.started | formatDate }}</span>
        </div>
        <div v-if="selectedConstruction.finished" class="mb-2">
          <strong>{{ $t("GENERAL.END") }}: </strong
          ><span>{{ selectedConstruction.finished | formatDate }}</span>
        </div>
        <div v-if="selectedConstruction.proof" class="mb-2">
          <strong>{{ $t("GENERAL.PROOF") }}: </strong
          ><span>{{ selectedConstruction.proof | formatDate }}</span>
        </div>
        <div v-if="selectedConstruction.bill" class="mb-2">
          <strong>{{ $t("GENERAL.BILL") }}: </strong
          ><span>{{ selectedConstruction.bill | formatDate }}</span>
        </div>
        <div v-if="selectedConstruction.description" class="mb-2">
          <strong>{{ $t("GENERAL.NOTE") }}: </strong
          ><span>{{ selectedConstruction.description }}</span>
        </div>
      </div>
    </b-modal>

    <b-modal id="showModal" hide-header hide-footer size="md" ref="showModal">
      <div v-if="isSelectedCostSheetLoaded" class="text-left">
        <div class="mb-2">
          <strong>{{ $t("LIST.NAME") }}: </strong
          ><span>{{ selectedCostSheet.name }}</span>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  name: "ListCostSheets",
  data() {
    return {
      costSheets: [],
      isCostSheetsLoaded: false,
      nameOrder: {
        query: "asc",
        isActive: false,
      },
      constructionOrder: {
        query: "asc",
        isActive: false,
      },
      amountOrder: {
        query: "asc",
        isActive: false,
      },
      selectedCostSheet: null,
      isSelectedCostSheetLoaded: false,
      selectedConstruction: null,
      isSelectedConstructionLoaded: false,
      isExportInProgress: false,
      appPath: process.env.VUE_APP_ROUTE_API_URL,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.ALL_COST_SHEETS"), route: "/cost-sheets/list" },
    ]);

    ApiService.get("cost_sheets", "?order[createdAt]=desc").then(({ data }) => {
      this.costSheets = data["hydra:member"];
      this.isCostSheetsLoaded = true;
    });
  },
  methods: {
    exportXLSX() {
      this.isExportInProgress = true;
      ApiService.get("cost_sheets/export").then(({ data }) => {
        this.isExportInProgress = false;
        if (data.status === "ok") {
          this.$notify({
            group: "notification",
            type: "success",
            title: "Cost Sheet export success",
          });
          window.open(data.downloadUrl, "_blank").focus();
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Cost Sheet export error",
          });
        }
      });
    },
    orderEntityByName() {
      this.nameOrder.isActive = true;
      if (this.nameOrder.query === "asc") {
        this.nameOrder.query = "desc";
      } else {
        this.nameOrder.query = "asc";
      }

      this.isCostSheetsLoaded = false;
      ApiService.get(
        "/cost_sheets",
        `?order[name]=${this.nameOrder.query}`
      ).then(({ data }) => {
        this.costSheets = [];
        this.costSheets = data["hydra:member"];
        this.isCostSheetsLoaded = true;
      });
    },
    orderEntityByConstruction() {
      this.constructionOrder.isActive = true;
      if (this.constructionOrder.query === "asc") {
        this.constructionOrder.query = "desc";
      } else {
        this.constructionOrder.query = "asc";
      }

      this.isCostSheetsLoaded = false;
      ApiService.get(
        "/cost_sheets",
        `?order[construction.name]=${this.constructionOrder.query}`
      ).then(({ data }) => {
        this.costSheets = [];
        this.costSheets = data["hydra:member"];
        this.isCostSheetsLoaded = true;
      });
    },
    orderEntityByAmount() {
      this.amountOrder.isActive = true;
      if (this.amountOrder.query === "asc") {
        this.amountOrder.query = "desc";
      } else {
        this.amountOrder.query = "asc";
      }

      this.isCostSheetsLoaded = false;
      ApiService.get(
        "/cost_sheets",
        `?order[amount]=${this.amountOrder.query}`
      ).then(({ data }) => {
        this.costSheets = [];
        this.costSheets = data["hydra:member"];
        this.isCostSheetsLoaded = true;
      });
    },
    showConstruction(id) {
      this.isSelectedConstructionLoaded = false;
      ApiService.get("constructions/" + id).then(({ data }) => {
        this.selectedConstruction = data;
        this.isSelectedConstructionLoaded = true;
        this.$refs["showConstructionModal"].show();
      });
    },
    showCostSheet(id) {
      this.isSelectedCostSheetLoaded = false;
      ApiService.get("cost_sheets/" + id).then(({ data }) => {
        this.selectedCostSheet = data;
        this.isSelectedCostSheetLoaded = true;
        this.$refs["showModal"].show();
      });
    },
    removeCostSheet(id) {
      const confirmation = confirm(this.$t("COSTSHEET.CONFIRMATION"));
      if (confirmation === false) return;

      ApiService.delete("cost_sheets/" + id).then(({ status }) => {
        if (status === 204) {
          this.costSheets = this.costSheets.filter(
            (product) => product.id !== id
          );
          this.$notify({
            group: "notification",
            type: "error",
            title: this.$t("COSTSHEET.DELETED"),
          });
        }
      });
    },
  },
};
</script>
